import { HttpInterceptorFn, HttpContextToken } from '@angular/common/http';
import { inject } from '@angular/core';
import { OauthService } from '../services/oauth.service';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

// Usage example:
// this.http.get('/api/public', { context: new HttpContext().set(SKIP_AUTH, true) });
export const accessTokenInterceptor: HttpInterceptorFn = (req, next) => {
    // Skip auth if the context flag is set
    if (req.context.get(SKIP_AUTH)) {
        return next(req);
    }

    const oauthService = inject(OauthService);
    const accessToken = oauthService.getAccessToken();

    if (accessToken) {
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    return next(req);
};
