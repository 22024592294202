import {Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    imports: [TranslateModule],
})
export class FooterComponent {
    date: Date;

    constructor() {
        this.date = new Date();
    }

}
