import {Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {TopbarUserButtonComponent} from './topbar-user-button/topbar-user-button.component';
import {environment} from '../../../environments/environment';
import {OauthService} from '../../core/services/oauth.service';


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    imports: [RouterLink, TopbarUserButtonComponent, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TopbarComponent implements OnInit {
    private readonly oauthService = inject(OauthService);

    usersPath = '/users';
    certificatesPath = '/certificates';
    queryParams;
    isLoggedIn = this.oauthService.isAuthenticated

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        const workspaceId = this.route.snapshot.queryParamMap.get('workspaceId');
        this.queryParams = {workspaceId: workspaceId};
    }

    openManual() {
        const windowReference = window.open();

        // Silently handle case where popup was blocked
        if (!windowReference) {
            return null; // Return null to indicate failure without notifications
        }

        windowReference.location.href = `${environment.bucketUrl}${environment.docsFolder}/manual.pdf`;
        return windowReference;
    }
}
