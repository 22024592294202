import {Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject, OnInit} from '@angular/core';
import {Alert, AlertService} from "../../../core/services/alert.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ShoelaceDialogDirective} from "../sl-dialog.directive";

@Component({
    selector: 'alerts',
    templateUrl: './alerts.component.html',
    styleUrl: './alerts.component.scss',
    imports: [ShoelaceDialogDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AlertsComponent implements OnInit {
    destroyRef = inject(DestroyRef)
    alerts: Alert[] = []

    constructor(private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.alertService.alerts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((alert) => {
            if (alert) {
                this.addAlert(alert);
            }
        })
    }

    addAlert(alert: Alert) {
        this.alerts = [...this.alerts, alert];
    }

    removeAlert(id: string| undefined) {
        if (!id) {
            return
        }
        this.alerts = this.alerts.filter(alert => alert.id !== id);
    }
}
