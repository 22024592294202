import {Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject, OnInit} from '@angular/core';
import {PrincipalAuthService} from '../../../core/services/principal-auth.service';
import {WorkspaceService} from '../../../core/services/workspace.service';
import {TranslateModule} from '@ngx-translate/core';

import {RouterLink} from '@angular/router';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {CapitalizeFirstPipe} from "../../../core/pipes/capitalize-first.pipe";
import {OauthService} from '../../../core/services/oauth.service';

@Component({
    selector: 'app-topbar-user-button',
    templateUrl: './topbar-user-button.component.html',
    styleUrls: ['./topbar-user-button.component.scss'],
    imports: [RouterLink, TranslateModule, CapitalizeFirstPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TopbarUserButtonComponent implements OnInit {

    destroyRef = inject(DestroyRef)
    oauthService = inject(OauthService);

    userName = '';
    userEmail = '';
    workspace = 'repsol';
    usersPath = '/users';
    marcoId = '';

    constructor(private principalAuthService: PrincipalAuthService,
                private workspaceService: WorkspaceService) {
    }

    ngOnInit() {
        this.loadActiveUser();
        this.workspace = this.workspaceService.getActiveWorkspace();
        this.workspaceService.subscribe(
            value => {
                if (value) {
                    this.workspace = value;
                }
            },
        );
    }

    logout() {
        this.principalAuthService.clearSession()
        void this.oauthService.logout();
    }

    showEnv() {
        return this.userEmail.includes("@finboot.com")
    }

    setEnv(id: number) {
        this.workspaceService.setWorkspace(id)
    }

    buildUserPath() {
        return `${this.usersPath}/${this.marcoId}`
    }

    private loadActiveUser() {
        this.principalAuthService.session().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeSession => {
            if (activeSession && activeSession.user) {
                this.userEmail = activeSession.user.identifier
                this.userName = activeSession.user.identityProfile.displayName;
                this.marcoId = activeSession.user.marcoId
            }
        });
    }

}
