import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OauthService } from '../services/oauth.service';

export const authGuard: CanActivateFn = async (_, state) => {
    const oauthService = inject(OauthService);

    // ensureAuthenticated will:
    // 1. Return true if already authenticated
    // 2. Try to refresh tokens if needed and return true if successful
    // 3. Redirect to login and return false if authentication fails
    return await oauthService.ensureAuthenticated(state.url);
};
