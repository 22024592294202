<div class="nav" role="navigation">
    <div class="brand-logo">
        <a href="/public"> <img src="/img/logo.png"/> </a>
    </div>

    <div>

        @if (isLoggedIn()) {
            <ul>
                <li>
                    <sl-button variant="text" size="large" [queryParamsHandling]="'preserve'"
                               [queryParams]="queryParams"
                               [routerLink]="certificatesPath">
                        {{ "menu.samples" | translate }}
                    </sl-button>
                </li>
                <li>
                    <sl-button variant="text" size="large" (click)="openManual()">
                        {{ "menu.manual" | translate }}
                    </sl-button>
                </li>
                <li>
                    <sl-button variant="text" size="large" [queryParamsHandling]="'preserve'"
                               [queryParams]="queryParams" [routerLink]="usersPath">
                        {{ "menu.users" | translate }}
                    </sl-button>
                </li>
                <li>
                    <app-topbar-user-button></app-topbar-user-button>
                </li>
            </ul>
        }
    </div>
</div>
