import {Routes} from '@angular/router';
import {authGuard} from "./core/guards/auth.guard";

export const appRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./pages/landing/landing.component').then(m => m.LandingComponent),
    },
    {
        path: 'callback',
        loadComponent: () =>
            import('./pages/callback/callback.component').then((c) => c.CallbackComponent),
    },
    {
        path: 'status/health',
        loadComponent: () => import('./pages/healthcheck/healthcheck.component').then(m => m.HealthcheckComponent)
    },
    {
        path: 'no-access',
        loadComponent: () => import('./pages/no-access/no-access.component').then(m => m.NoAccessComponent)
    },
    {
        path: 'home',
        loadComponent: () => import('./pages/certificates-list/certificates-list.component').then(m => m.CertificatesListComponent),
        canActivate: [authGuard],
    },
    {
        path: 'certificates',
        loadComponent: () => import('./pages/certificates-list/certificates-list.component').then(m => m.CertificatesListComponent),
        canActivate: [authGuard],
    },
    {
        path: 'certificates/:sampleId',
        loadComponent: () => import('./pages/certificate-details/certificate-details.component').then(m => m.CertificateDetailsComponent),
        canActivate: [authGuard],
    },
    {
        path: 'create',
        loadComponent: () => import('./pages/create-certificate/create-certificate.component').then(m => m.CreateCertificateComponent),
        canActivate: [authGuard],
    },
    {
        path: 'users',
        loadComponent: () => import('./pages/users-list/users-list.component').then(m => m.UsersListComponent),
        canActivate: [authGuard],
    },
    {
        path: 'users/:marcoId',
        loadComponent: () => import('./pages/user-details/user-details.component').then(m => m.UserDetailsComponent),
        canActivate: [authGuard],
    },
    {
        path: 'invite',
        loadComponent: () => import('./pages/invite-user/invite-user.component').then(m => m.InviteUserComponent),
        canActivate: [authGuard],
    },
    {path: '404', loadComponent: () => import('./pages/not-found/not-found.component').then(m => m.NotFoundComponent)},
    {path: '**', redirectTo: 'home'}
];



