import {HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import LocalManager from "../support/localManager";
import {HttpCancelService} from "../services/HttpCancelService";
import {OauthService} from '../services/oauth.service';

export const sessionRecoveryInterceptor: HttpInterceptorFn = (req, next: HttpHandlerFn) => {
    const router = inject(Router);
    const oauthService = inject(OauthService);
    const httpCancelService = inject(HttpCancelService);
    const localManager = inject(LocalManager)

    return next(req).pipe(
        takeUntil(httpCancelService.getCancelPendingRequests$()), // Cancel ongoing requests
        catchError((err: HttpErrorResponse) => {
            if (err.error.message === 'User has not been invited to the application') {
                localManager.setActiveSession(null);
                httpCancelService.cancelPendingRequests();
                router.navigate(['no-access']);
                return throwError(() => new Error('User has not been invited to the application'));
            }

            if (err.status === 401) {
                localStorage.setItem('nextUrl', router.url);
                httpCancelService.cancelPendingRequests();
                oauthService.startAuthFlow(router.url);
                return throwError(() => new Error('Unauthorized access - redirecting to login'));
            }

            return throwError(() => err);
        }),
    );
};
